<template>
  <v-row>
    <v-col md="8" sm="12" class="px-6 py-9">
      <v-row>
        <v-col>
          <v-row class="d-flex flex-row align-center justify-start">
            <div style="max-width: 6rem;" class="mr-2">
              <v-select
                :value="rangeType"
                :items="rangeTypes"
                item-text="name"
                item-value="value"
                dense
                hide-details
                class="remove__border"
                @input="value => $store.commit('calendar/updateRange', value)"
              >
                <template #selection="{ item }">
                  <span class="d-flex justify-center align-center primary--text caption font-weight-medium pb-1">
                    {{ item.name }}
                  </span>
                </template>
                <template #prepend>
                  <v-icon color="primary" small>
                    remove_red_eye
                  </v-icon>
                </template>
                <template #append>
                  <v-icon color="primary" small style="padding-top: 3px;">
                    mdi-chevron-down
                  </v-icon>
                </template>
              </v-select>
            </div>
            <v-btn
              outlined
              color="primary"
              x-small
              style="border: 0 !important;"
              @click="$emit('setToday'); $emit('updateSelectedDate', null)"
            >
              <v-icon color="primary" small>
                location_on
              </v-icon>
              <span class="ml-2" v-text="$t('common.today')" />
            </v-btn>
            <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
            >
              <template #activator="{ on, attrs}">
                <v-row
                  v-bind="attrs"
                  class="d-flex flex-row align-center ml-2"
                  v-on="on"
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    calendar_today
                  </v-icon>
                  <span
                    class="primary--text caption font-weight-medium mx-2"
                    v-text="datePickerFormatted"
                  />
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-chevron-down
                  </v-icon>
                </v-row>
              </template>
              <VueCtkDateTimePicker
                :value="selectedDate"
                class="date-picker"
                no-shortcuts
                no-button-now
                inline
                only-date
                auto-close
                format="YYYY-MM-DD"
                color="#2acccc"
                @input="date => $emit('updateSelectedDate', date)"
              >
                <!-- throws error due to npm package bug, fixed in https://github.com/chronotruck/vue-ctk-date-time-picker/pull/324 -->
                <template #default class="d-flex flex-row justify-center align-center py-0">
                  <v-btn
                    color="primary"
                    class="ml-auto mt-0"
                    style="width: 100%;"
                    @click="updateDateSelection"
                  >
                    <span
                      class="mr-1"
                    >
                      {{ $t('common.resetSearch') }}
                    </span>
                    <v-icon
                      size="32"
                    >
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </template>
              </VueCtkDateTimePicker>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import { DateTime } from 'luxon'

export default {
  name: 'CalendarControllers',
  components: {
    VueCtkDateTimePicker,
  },
  props: {
    selectedDate: {
      type: String,
      default: null,
    },
    calendar: {
      type: String,
      default: null,
    },
    selectedRangeTarget: {
      type: String,
      default: null,
    },
    rangeType: {
      type: String,
      default: null,
    },
  },
  computed: {
    rangeTypes() {
      return [
        {
          name: this.$t('common.month'),
          value: 'month',
        },
        {
          name: this.$t('common.week'),
          value: 'week',
        },
        {
          name: this.$t('common.day'),
          value: 'day',
        },
      ]
    },
    datePickerFormatted({ selectedDate, calendar, selectedRangeTarget, rangeType }) {
      const selectedRange = selectedDate ? selectedRangeTarget : calendar
      const start = DateTime.fromISO(selectedRange).startOf(rangeType).setLocale(this.$i18n.locale).toFormat('ccc dd LLL yyyy')
      const end = DateTime.fromISO(selectedRange).endOf(rangeType).setLocale(this.$i18n.locale).toFormat('ccc dd LLL yyyy')
      if (rangeType === 'day') {
        return start
      }
      return `${start} - ${end}`
    },

  },
  methods: {
    updateDateSelection() {
      this.$store.commit('calendar/updateDate', new Date().toISOString().substr(0, 10))
      this.$emit('updateSelectedDate', null)
      this.$store.commit('calendar/updateRange', 'week')
    },

  },
}
</script>
